import cloneDeep from 'lodash.clonedeep';
import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export const applicationSteps = cloneDeep({
  businessInstalmentLoan: {
    initial: [
      { text: $gettext('Start'), name: 'businessLoanStart' },
      { text: $gettext('Company Selection'), name: 'businessLoanCompanySelection' },
      { text: $gettext('Corporate information'), name: 'businessLoanCompanyInformation' },
      { text: $gettext('Guarantee'), name: 'businessLoanApplicant' },
      { text: $gettext('Loan Offer'), name: 'businessLoanOffer' },
      { text: $gettext('Signees'), name: 'businessLoanSignees' },
      { text: $gettext('Agree'), name: 'businessLoanTerms' },
    ],
    signee: [
      { text: $gettext('Signature'), name: 'businessLoanSigneeStart' },
      { text: $gettext('Agree'), name: 'businessLoanTerms' },
    ],
  },
  smePosInstalmentLoan: {
    initial: [
      { text: $gettext('Start'), name: 'smePosMerchantStart' },
      { text: $gettext('Corporate information'), name: 'smePosCompanyInformation' },
      { text: $gettext('Applicant'), name: 'smePosApplicant' },
      { text: $gettext('The beneficial owners'), name: 'smePosOwnersInformation' },
      { text: $gettext('Ready'), name: 'smePosMerchantComplete' },
    ],
    applicant: [
      { text: $gettext('Start'), name: 'smePosLoanApplicantStart' },
      { text: $gettext('Loan Offer'), name: 'businessLoanOffer' },
      { text: $gettext('Confirmation'), name: 'smePosBusinessLoanTerms' },
      { text: $gettext('Ready'), name: 'smePosApplicantComplete' },
    ],
  },
  carInstalmentLoan: {
    initial: [
      { text: $gettext('Basic information'), name: 'carLoanStart' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Loan Offer'), name: 'loanOffer' },
      { text: $gettext('Confirmation'), name: 'carLoanTerms' },
      { text: $gettext('Ready'), name: 'customerReady' },
    ],
  },
  instalmentLoan: {
    initial: [
      { text: $gettext('Preliminary informations'), name: 'start' },
      { text: $gettext('Basic information'), name: 'basicInformation' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Ability to pay'), name: 'kreditz' },
      { text: $gettext('Bank account selection'), name: 'bankAccountSelection' },
      { text: $gettext('Loan Offer'), name: 'loanOffer' },
      { text: $gettext('Confirmation'), name: 'instalmentLoanTerms' },
      { text: $gettext('Ready'), name: 'instalmentLoanComplete' },
    ],
  },
  continuousLoan: {
    initial: [
      { text: $gettext('Preliminary informations'), name: 'start' },
      { text: $gettext('Basic information'), name: 'basicInformation' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Ability to pay'), name: 'kreditz' },
      { text: $gettext('Bank account selection'), name: 'bankAccountSelection' },
      { text: $gettext('Confirmation'), name: 'continuousLoanTerms' },
      { text: $gettext('Ready'), name: 'complete' },
    ],
  },
  brokeredContinuousLoan: {
    initial: [
      { text: $gettext('Preliminary informations'), name: 'start' },
      { text: $gettext('Basic information'), name: 'basicInformation' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Application sent'), name: 'applicationSent' },
      { text: $gettext('Loan Offer'), name: 'loanOffer' },
      { text: $gettext('Offer selected'), name: 'brokeringCompletedOffers' },
      { text: $gettext('Ready'), name: 'brokeringComplete' },
    ],
  },
  brokeredInstalmentLoan: {
    initial: [
      { text: $gettext('Preliminary informations'), name: 'start' },
      { text: $gettext('Basic information'), name: 'basicInformation' },
      { text: $gettext('Customer information'), name: 'customerQuestions' },
      { text: $gettext('Application sent'), name: 'applicationSent' },
      { text: $gettext('Loan Offer'), name: 'loanOffer' },
      { text: $gettext('Offer selected'), name: 'brokeringCompletedOffers' },
      { text: $gettext('Ready'), name: 'brokeringComplete' },
    ],
  },
});

export const sellerSteps = cloneDeep([
  { text: $gettext('Vehicle information'), name: 'salesDetails' },
  { text: $gettext('Ready'), name: 'complete' },
]);

export const soloSteps = cloneDeep([
  { name: 'applicationNotFound', text: $gettext('The Application was not found') },
  { name: 'businessLoanComplete', text: $gettext('Corporate loan granted') },
  { name: 'businessLoanExpired', text: $gettext('The deadline has expired') },
  { name: 'businessLoanNoCompanyConnections', text: $gettext('No business connections') },
  { name: 'businessLoanPending', text: $gettext('Waiting for signatures') },
  { name: 'businessLoanProcurationAbstractionRejection', text: $gettext('The application was suspended') },
  { name: 'businessLoanRejected', text: $gettext('The application was suspended') },
  { name: 'businessLoanSigned', text: $gettext('Signed') },
  { name: 'businessLoanSigningComplete', text: $gettext('Corporate loan granted') },
  { name: 'businessLoanSigningPending', text: $gettext('Signature') },
  { name: 'connectionError', text: $gettext('Connection error') },
  { name: 'linkExpired', text: $gettext('Expired application') },
  { name: 'loanOffer', text: $gettext('Loan Offer') },
  { name: 'kreditz', text: $gettext('Ability to pay') },
  { name: 'bankAccountSelection', text: $gettext('Bank account selection') },
  { name: 'reject', text: $gettext('The application was suspended') },
]);
